/* eslint-disable no-useless-escape */
export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

// check link image
export const isImageValid = (url) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
            resolve(url);
        };
        img.onerror = function () {
            resolve('');
        };
        img.src = url;
    });
};

export function isObjectOrArray(input) {
    return typeof input === 'object' && input !== null && !Array.isArray(input);
}

export const isImageValidLink = (url, callback) => {
    const img = new Image();
    img.onload = function () {
        callback(true);
    };
    img.onerror = function () {
        callback(false);
    };
    img.src = url;
};

export const isImageUrl = (url) => {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|bmp)$/i;
    return imageUrlRegex.test(url);
};

export const isVideoUrl = (url) => {
    // const videoUrlRegex = /\.(mp4|webm|ogg)$/i;
    const youtubeRegex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return youtubeRegex.test(url);
};
export function compareNumbers(inputString) {
    const [beforeSlash, afterSlash] = inputString.split('/').map(Number);

    if (beforeSlash >= afterSlash) {
        return false;
    }

    return true;
}


export const validateVideo = (input) => {
    const isYouTubeLink = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*&v=))([^"&?\/\s]{11})/.test(
        input,
    );

    if (isYouTubeLink) return 'Youtube';

    const isVideoMp4 = /\.(mp4|webm|ogg)/.test(input);

    if (!isVideoMp4) {
        console.log('video is removed');
    } else return true;
};

export const convertToPreview = (input) => {
    // Extract the video ID from the YouTube link
    const videoIdMatch = input.match(
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*&v=))([^"&?\/\s]{11})/,
    );

    if (videoIdMatch) {
        const videoId = videoIdMatch[1];

        // Construct the embed code
        const embedCode = `https://www.youtube.com/embed/${videoId}`;
        return embedCode;
    } 
};