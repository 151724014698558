import { SortData } from '../../../utils/sorting';
import { cartInit } from '../../init/cartInit';

function cartReducer(state, action) {
    // const username = localStorage.getItem('username');
    // const localPath = `shopping_cart-${username}`;

    switch (action?.type) {
        case 'ADD_CART':
            const newItem = action.payload;

            // const duplicate = state?.data?.find(
            //     (e) =>
            //         e?.id === newItem.id ||
            //         (e?.version === newItem.version &&
            //             e.perMonth === newItem.perMonth &&
            //             e.totalQa === newItem.totalQa &&
            //             e.totalTraining === newItem.totalTraining),
            // );

            // if (duplicate) {
            //     const cartUpdate =
            //         state?.data?.filter(
            //             (e) =>
            //                 e?.version !== duplicate.version ||
            //                 e.perMonth !== duplicate.perMonth ||
            //                 e.totalQa !== duplicate.totalQa ||
            //                 e.totalTraining !== duplicate.totalTraining,
            //         ) || [];

            //     cartUpdate.push({
            //         ...duplicate,
            //         id: duplicate?.id,
            //         quantity: +newItem.quantity + +duplicate.quantity,
            //     });

            //     return {
            //         ...state,
            //         data: SortData(cartUpdate),
            //     };
            // } else

            const filterDataAdd = SortData([
                ...state.data,
                {
                    ...newItem,
                },
            ]);

            // localStorage.setItem(localPath, JSON.stringify(filterDataAdd));
            return {
                ...state,
                data: filterDataAdd,
            };

        case 'UPDATE_CART':
            const findItem = state?.data?.find((e) => e?.id === action.payload.id);
            const filterData = state?.data?.filter((item) => item.id !== findItem.id);
            const sortData = [...filterData, { ...action.payload }];

            // localStorage.setItem(localPath, JSON.stringify(SortData(sortData)));

            return {
                ...state,
                data: SortData(sortData, 'createAt'),
            };

        case 'DELETE_CART':
            const filterDataDelete = state?.data?.filter((item) => item.id !== action.payload.id);

            // localStorage.setItem(localPath, JSON.stringify(SortData(filterDataDelete)));

            return {
                ...state,
                data: SortData([...filterDataDelete], 'createAt'),
            };

        case 'RESET_CART':
            const arrCart = action.payload;
            // localStorage.setItem(localPath, JSON.stringify(SortData(arrCart)));
            return {
                ...cartInit,
                data: SortData(arrCart, 'createAt'),
            };
        case 'CREATE_PAYMENT':
            return {
                ...state,
                ...action.payload,
            };

        case 'UPDATE_PAYMENT':
            return {
                ...state,
                ...action.payload,
            };

        default:
            throw new Error('invalid action');
    }
}
export default cartReducer;
