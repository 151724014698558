import { decodeToken } from 'react-jwt';
import { getCookie } from '../../utils/cookie';

const infoUser = localStorage.getItem('info');

const decodeInfo = decodeToken(infoUser);

const authInit = {
    user: {
        access_token: getCookie('access_token'),
        refresh_token: getCookie('refresh_token'),
        ...decodeInfo,
    },
};

export { authInit };
