import { ClearCookies, setCookie } from "../../utils/cookie";

function reducer(state, action) {
    switch (action?.type) {
        case 'setUser':
            return {
                ...state,
                user: action?.payload,
            };
        case 'Logout':
            setCookie('refresh_token', null);
            setCookie('access_token', null);
            localStorage.removeItem('info');
            ClearCookies();
            return {
                user: null,
            };
        case 'tracking':
            return {
                ...state,
                ...action?.payload,
            };
        case 'isCreateBot':
            return {
                ...state,
                ...action?.payload,
            };
        case 'isPayment':
            return {
                ...state,
                ...action?.payload,
            };
        case 'setAivBot':
            return {
                ...state,
                ...action?.payload,
            };

        default:
            throw new Error('invalid action');
    }
}
export default reducer;
