// Hàm lưu giá trị vào localStorage
export function saveToLocalStorage(type, field, value) {
    let data = JSON.parse(localStorage.getItem('website_configuration')) || {};

    if (!data[type]) {
        data[type] = {};
    }

    data[type][field] = value;

    localStorage.setItem('website_configuration', JSON.stringify(data));
}

// Hàm lấy giá trị từ localStorage
export function getFromLocalStorage(type, field) {
    const data = JSON.parse(localStorage.getItem('website_configuration')) || {};

    if (data[type] && data[type][field]) {
        return data[type][field];
    }

    return null;
}

// Hàm xoá giá trị khỏi localStorage
export function removeFromLocalStorage(type, field) {
    let data = JSON.parse(localStorage.getItem('website_configuration')) || {};

    if (data[type]) {
        if (field) {
            delete data[type][field];

            // Nếu không còn field nào trong type, xoá luôn type
            if (Object.keys(data[type]).length === 0) {
                delete data[type];
            }
        } else {
            delete data[type];
        }

        localStorage.setItem('website_configuration', JSON.stringify(data));
    }
}
