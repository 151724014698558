import React, { useReducer } from 'react';
import aivBotContext from '../context/aivBotContext';
import authContext from '../context/authContext';
import trackingContext from '../context/trackingContext';
import isPaymentContext from '../context/isPayment';
import createBotContext from '../context/isCreateBot';
import { aivBotInit } from '../init/aivBotInit';
import { authInit } from '../init/authInit';
import { trackingInit } from '../init/trackingInit';
import { isCreateBot } from '../init/createBot';
import { isPaymentSate } from '../init/payment';
import reducer from '../reducer';
import CartContextProvider from './cartContextProvider';

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, authInit);
    const [trackingState, trackingDispatch] = useReducer(reducer, trackingInit);
    const [aivBotState, aivBotDispatch] = useReducer(reducer, aivBotInit);
    const [isCreateBotState, isCreateBotDispatch] = useReducer(reducer, isCreateBot);
    const [isPaymentState, isPaymentDispatch] = useReducer(reducer, isPaymentSate);

    return (
        <authContext.Provider value={[state, dispatch]}>
            <isPaymentContext.Provider value={[isPaymentState, isPaymentDispatch]}>
                <trackingContext.Provider value={[trackingState, trackingDispatch]}>
                    <createBotContext.Provider value={[isCreateBotState, isCreateBotDispatch]}>
                        <aivBotContext.Provider value={[aivBotState, aivBotDispatch]}>
                            <CartContextProvider>{children}</CartContextProvider>
                        </aivBotContext.Provider>
                    </createBotContext.Provider>
                </trackingContext.Provider>
            </isPaymentContext.Provider>
        </authContext.Provider>
    );
};

export default Provider;
