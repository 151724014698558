export const fadeInUp = {
    initial: {
        y: '30%',
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
        },
    },
};

export const fadeInUpDelay1 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.3,
        },
    },
};

export const fadeInUpDelay2 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.5,
        },
    },
};

export const fadeInUpDelay3 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.7,
        },
    },
};
export const fadeInDown = {
    initial: {
        y: -60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

export const fadeInLeft = {
    initial: {
        x: -60,
        opacity: 0,
        transition: { duration: 0.03, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

export const fadeInRight = {
    initial: {
        x: 60,
        opacity: 0,
        transition: { duration: 0.03, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
        },
    },
};
export const fadeInDiagonal = {
    initial: {
        x: 68,
        y: -48,
        opacity: 0,
        transition: { duration: 0.75, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.75,
            ease: 'easeInOut',
        },
    },
};
export const fadeInDiagonalUp = {
    initial: {
        x: 68,
        y: 35,
        opacity: 0,
        transition: { duration: 0.75, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.75,
            ease: 'easeInOut',
        },
    },
};
export const DropDown1 = {
    initial: {
        height: 0,
        opacity: 0,
        transition: {
            height: { duration: 0.1, ease: 'easeInOut' },
            opacity: { duration: 0.1, ease: 'easeInOut' },
        },
        pointerEvents: 'none',
    },
    animate: {
        height: 'auto',
        opacity: 1,
        transition: {
            height: { duration: 0.1, ease: 'easeInOut' },
            opacity: { duration: 0.1, ease: 'easeInOut' },
        },
        pointerEvents: 'auto',
    },
    exit: {
        height: 0,
        opacity: 0,
        transition: {
            height: { duration: 0.1, ease: 'easeInOut' },
            opacity: { duration: 0.1, ease: 'easeInOut' },
        },
    },
};
export const DropDown = {
    initial: {
        height: 0,
        opacity: 0,
        transition: {
            height: { duration: 0.5, ease: 'easeInOut' },
            opacity: { duration: 0.5, ease: 'easeInOut' },
        },
        pointerEvents: 'none',
    },
    animate: {
        height: 'auto',
        opacity: 1,
        transition: {
            height: { duration: 0.5, ease: 'easeInOut' },
            opacity: { duration: 0.5, ease: 'easeInOut' },
        },
        pointerEvents: 'auto',
    },
    exit: {
        height: 0,
        opacity: 0,
        transition: {
            height: { duration: 0.2, ease: 'easeInOut' },
            opacity: { duration: 0.5, ease: 'easeInOut' },
        },
    },
};

export const pingVariants = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.7,
        },
    },
};

export const fadeOutVariants = {
    hidden: { opacity: 1, transition: { duration: 0.3, ease: 'easeInOut' } },
    visible: { opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } },
};

export const dropdownAnimation = {
    visible: {
        opacity: 1,
        rotateX: 0,
        transition: {
            duration: 0.3,
        },
        display: 'block',
    },
    hidden: {
        opacity: 0,
        rotateX: -15,
        transition: {
            duration: 0.3,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

export const showAnimation = {
    hidden: {
        width: 0,
        opacity: 0,
        x: '-300px',
        transition: {
            duration: 0.3,
        },
    },
    show: {
        opacity: 1,
        width: 'auto',
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
};

export const showAnimationRightSide = {
    hidden: {
        width: 0,
        opacity: 0,
        x: '300px',
        transition: {
            duration: 0.3,
        },
    },
    show: {
        opacity: 1,
        width: 'auto',
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
};
