import React, { useReducer } from 'react';
import cartContext from '../../context/cartContext';
import { cartInit } from '../../init/cartInit';
import cartReducer from '../../reducer/cart';

const CartContextProvider = ({ children }) => {
    const [cartState, cartDispatch] = useReducer(cartReducer, cartInit);

    // const handleCheckCart = () => {
    //     let result = [];
    //     try {
    //         const username = localStorage.getItem('username');
    //         const path = `shopping_cart-${username}`;
    //         const dataArr = JSON.parse(localStorage.getItem(path));
    //         const dataArrDefault = JSON.parse(localStorage.getItem('shopping_cart-null'));
    //         if (dataArr?.length > 0) {
    //             result = dataArr;
    //         } else if (dataArrDefault?.length > 0) result = dataArrDefault;
    //     } catch (error) {
    //         result = [];
    //     }

    //     cartDispatch(resetCart(result));
    // };

    // useEffect(() => {
    //     handleCheckCart();
    // }, []);

    return <cartContext.Provider value={[cartState, cartDispatch]}>{children}</cartContext.Provider>;
};

export default CartContextProvider;
