import { message, notification } from 'antd';
import { extractAndRemoveLink, extractLink } from '../custom';
import { hasLink } from '../sorting';

export const notiMessageError = (data) => {
    if (!data) return;
    if (data?.error?.includes('personal mode')) {
        message.error('Không thể bật cá nhân hóa khi chưa có form');
    } else if (data[0] === "Error': 'User openai apikey incorrect!!!") {
        message.error('OpenAI API key của người dùng không chính xác');
    } else if (data?.msg?.includes('OpenAI API Key không hợp lệ')) {
        notification.open({
            message: extractAndRemoveLink(data?.msg),
            description: (
                <a
                    className="border-b text-sm  text-primary"
                    href={extractLink(data?.msg)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {extractLink(data?.msg) || ''}
                </a>
            ),
        });
    } else if (data?.openai_api_base) {
        message.error(data?.openai_api_base[0]);
    } else if (data?.source_llm?.length > 0) {
        message.error(data?.source_llm[0]);
    } else if (data?.detail) {
        message.error(data?.detail);
    } else if (data?.msg) {
        message.error(data?.msg);
    } else {
        message.error('Đã có lỗi sảy ra');
    }
};

export const notiDetailError = (res) => {
    const errorJSON = res.data?.detail?.split(' - ');
    const validJsonString = errorJSON[1]?.replace(/'/g, '"').replace(/None/g, 'null');
    const errorObject = JSON.parse(validJsonString);
    if (hasLink(errorObject?.error?.message)) {
        notification.open({
            message: extractAndRemoveLink(errorObject?.error?.message),
            description: (
                <a
                    className="border-b text-sm  text-primary"
                    href={extractLink(errorObject?.error?.message)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {extractLink(errorObject?.error?.message) || ''}
                </a>
            ),
        });
    } else {
        message.error(errorObject?.error?.message);
    }
};

export const copyToClipBoard = async (copyMe) => {
    try {
        await navigator.clipboard.writeText(copyMe);
        message.success('Đã sao chép');
    } catch (err) {
        message.error('Sao chép thất bại');
    }
};