function extractAndRemoveLink(inputString) {
    const linkRegex = /https?:\/\/[^\s]+/g;
    const matches = inputString.match(linkRegex);

    if (matches) {
        // Nếu có link, cắt chúng khỏi chuỗi và trả về cả chuỗi mới và mảng chứa link
        const stringWithoutLinks = inputString?.replace(linkRegex, '').trim();
        // return { stringWithoutLinks, links: matches };
        return stringWithoutLinks;
    } else {
        // Nếu không có link, trả về null cho cả chuỗi mới và mảng link
        return inputString;
    }
}
function extractLink(inputString) {
    const linkRegex = /https?:\/\/[^\s]+/g;
    const matches = inputString.match(linkRegex);

    // Trả về mảng chứa các link hoặc null nếu không tìm thấy
    return matches || null;
}

const convertTime = (inputDateTime) => {
    if (!inputDateTime) return;
    const [time, date] = inputDateTime.split(' ');
    const [hour, minute, second] = time.split(':');
    const [day, month, year] = date?.split('-');
    const formattedDateTime = new Date(year, month - 1, day, hour, minute, second);
    const currentTime = new Date();
    if (currentTime > formattedDateTime) {
        return 'Đã hết hạn';
    }

    const formattedString = `${formattedDateTime.getDate()}/${
        formattedDateTime.getMonth() + 1
    }/${formattedDateTime.getFullYear()} ${formattedDateTime.getHours()}:${formattedDateTime.getMinutes()}`;
    return formattedString;
};

export { extractAndRemoveLink, extractLink, convertTime };
