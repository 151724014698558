import { isImageValid } from '../validatetor';
import { uuid } from 'short-uuid';

export const SortData = (data = [], key) => {
    return data.sort((a, b) => (a?.[key] > b?.[key] ? 1 : a?.[key] < b?.[key] ? 1 : 0));
};
//
export function hasLink(str) {
    const linkRegex = /(https?:\/\/[^\s]+)/gi;
    return linkRegex.test(str);
  }
  
// sort and remove duplicate images
export async function filterAndRemoveDuplicates(arr) {
    let data = [];
    if (arr && arr?.length !== 0) {
        const filteredArr = arr?.filter((item) => item !== null && !Number.isNaN(item) && item !== 'nan');
        const uniqueArr = [...new Set(filteredArr)];

        // Sử dụng Promise.all
        await Promise.all(
            uniqueArr.map(async (link) => {
                const result = await isImageValid(link);
                if (result) data.push(result);
            }),
        );
    }
    return data;
}

// sort and remove duplicate

export function filterAndRemoveDuplicatesList(arr) {
    if (arr && arr?.length !== 0) {
        const filteredArrs = arr?.filter(
            (item) => item !== null && item !== '' && !Number.isNaN(item) && item !== 'nan',
        );
        const uniqueArr = [...new Set(filteredArrs)];
        return uniqueArr;
    }
}

export const filterDuplicateQuestions = (data) => {
    const uniqueQuestions = new Set();
    const result = [];

    for (const item of data) {
        const question = item.question;
        if (question !== null && !uniqueQuestions.has(question) && question !== 'Nan' && question !== '') {
            uniqueQuestions.add(question);
            result.push(item);
        }
    }

    return result;
};

export function filterAndHandleSpecialValues(arr) {
    const newFlow = arr.filter((i) => i.question !== null && i.question !== 'nan');
    const uniqueIds = new Set();
    const filteredArray = [];

    for (const item of newFlow) {
        if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            filteredArray.push(item);
        }
    }
    return filteredArray;
}
export function isCurrentTimeGreaterThan(inputTime) {
    if (!inputTime) return;
    // Tách thông tin thời gian và ngày từ chuỗi đầu vào
    const [time, date] = inputTime?.split(' ');

    // Tách giờ, phút, giây từ chuỗi thời gian
    const [hour, minute, second] = time?.split(':');

    // Tách ngày, tháng, năm từ chuỗi ngày
    const [day, month, year] = date?.split('-');

    // Tạo đối tượng Date từ thông tin đã tách
    const inputDateTime = new Date(year, month - 1, day, hour, minute, second);
    // Kiểm tra nếu thời gian hiện tại lớn hơn thời gian đầu vào
    const currentTime = new Date();
    const isDate = currentTime > inputDateTime;

    if (isDate) {
        return false;
    } else {
        return true;
    }
}
export function timeSince(dateString) {
    if (!dateString) return;
    const now = new Date();
    const past = new Date(dateString);
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30; // Sử dụng giả định 1 tháng = 30 ngày
    const elapsed = now - past;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' giây trước';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' phút trước';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' giờ trước';
    } else if (elapsed < msPerMonth) {
        return 'khoảng ' + Math.round(elapsed / msPerDay) + ' ngày trước';
    } else {
        return 'khoảng ' + Math.round(elapsed / msPerMonth) + ' tháng trước';
    }
}

export const convertToPairs = (data) => {
    const pairs = [];
    let currentPair = {};
    data.forEach((item) => {
        if (item.role === 'assistant') {
            currentPair.answer = item.content;
            currentPair.file_ids = item.file_ids;
        } else if (item.role === 'user') {
            currentPair.question = item.content;
            currentPair.file_ids = item.file_ids;
            pairs.push({ ...currentPair, id: uuid() });
            currentPair = {};
        }
    });

    return pairs;
};



export const getAllIds = (inputArray) => {
    const resultArray = [];

    inputArray.forEach((item) => {
        if (item.data && item.data.length > 0) {
            item.data.forEach((dataItem) => {
                if (dataItem.id) {
                    resultArray.push(dataItem.id);
                }
            });
        }
    });

    return resultArray;
};

export const constConvertHistory = (arr = []) => {
    if (!arr || arr.length === 0) return [];
    const newArr = arr.reduce((result, message, index) => {
        if (message?.content?.length > 0) {
            if (index % 2 === 0) {
                result.push({
                    question: message.role === 'user' && message.content,
                    answer: 'Đã có lỗi xảy ra. Vui lòng thử lại sau.',
                    id: uuid(),
                });
            } else {
                const prevIndex = Math.floor(index / 2);
                if (message.role === 'assistant') {
                    if (result[prevIndex]) {
                        result[prevIndex].answer = message?.content;
                        if (message.vote) result[prevIndex].react = message?.vote;
                    }
                }
            }
        }
        return result;
    }, []);

    return newArr;
};
export const convertDataHistorychat = (data) => {
    const pairs = [];
    let currentPair = {};
    data.forEach((item) => {
        if (item.role === 'assistant') {
            // Nếu là assistant, gán nội dung của item vào currentPair.answer
            currentPair.answer = item.content;
            currentPair.file_ids = item.file_ids;
            if (currentPair.question) {
                // Nếu currentPair có cả question và answer, thêm vào mảng pairs
                pairs.push({ ...currentPair, id: uuid() });
                // Reset currentPair về một object trống để chuẩn bị cho cặp tiếp theo
                currentPair = {};
            }
        } else if (item.role === 'user') {
            // Nếu là user, gán nội dung của item vào currentPair.question
            currentPair.question = item.content;
            currentPair.file_ids = item.file_ids;
        }
    });

    // Sau khi lặp qua tất cả các phần tử, kiểm tra xem currentPair có cả question và answer không
    // Nếu có, thêm vào mảng pairs
    if (currentPair.question && currentPair.answer) {
        pairs.push({ ...currentPair, id: uuid() });
    }

    return pairs;
};


export const handleFilterData = (arr) => {
    const categorizedData = {
        today: [],
        yesterday: [],
        lastWeek: [],
        lastMonth: [],
        older: [],
    };

    // Get the current date
    const currentDate = new Date();

    arr.forEach((item) => {
        const updatedAt = new Date(item.updated_at || item.created_at);

        // Calculate the time difference in milliseconds
        const timeDiff = currentDate - updatedAt;
        const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

        switch (true) {
            case daysDiff < 1:
                categorizedData.today.push(item);
                break;
            case daysDiff < 2:
                categorizedData.yesterday.push(item);
                break;
            case daysDiff <= 7:
                categorizedData.lastWeek.push(item);
                break;
            case daysDiff <= 30:
                categorizedData.lastMonth.push(item);
                break;
            default:
                categorizedData.older.push(item);
                break;
        }
    });

    return [
        {
            title: 'Hôm nay',
            data: SortData(categorizedData.today, 'updated_at'),
        },
        {
            title: 'Hôm qua',
            data: SortData(categorizedData.yesterday, 'updated_at'),
        },
        {
            title: 'Tuần trước',
            data: SortData(categorizedData.lastWeek, 'updated_at'),
        },
        {
            title: 'Tháng trước',
            data: SortData(categorizedData.lastMonth, 'updated_at'),
        },
        {
            title: 'Cũ hơn',
            data: SortData(categorizedData.older, 'updated_at'),
        },
    ];
};
export function isAllDigits(inputString) {
    const regex = /^[0-9]+$/;
    return !regex.test(inputString);
}

export function isOnlyWhitespace(str) {
    return /^\s*$/.test(str);
}

export const validateLink = (url) => {
    const documentExtensions = /\.(doc|docx|pdf|txt)$/i;
    const googleDocsPattern = /^https?:\/\/docs\.google\.com\/(document|spreadsheets|presentation)\//;
    return documentExtensions.test(url) || googleDocsPattern.test(url);
};

export const convertToExportUrl = (editUrl) => {
    const fileId = editUrl.match(/\/d\/(.+?)\//)[1];
    const check = editUrl.includes('pub?output=xlsx');
    if (check) {
        return editUrl;
    }
    const exportUrl = `https://docs.google.com/spreadsheets/d/${fileId}/export?format=xlsx`;
    return exportUrl;
};

export function formatString(str) {
    // Take the last four digits
    const lastFourDigits = str.slice(-4);
    // Create the masked string with the specific format
    return `***** *** **** ${lastFourDigits}`;
  }

   
  export const convertObjToArr = (obj) => {
    let convertArray = [];
    try {
        for (const [key, value] of Object.entries(obj)) {
            convertArray.push({
                value: value,
                label: value,
            });
        }
    } catch (error) {
        convertArray = [];
    }
    return convertArray || [];
};

// fotmat date
export function convertIntegerToDate(integerDate) {
    const baseDate = new Date('1900-01-01');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysToAdd = integerDate - 2;
    return new Date(baseDate.getTime() + daysToAdd * millisecondsPerDay);
}
export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });