import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '../components/base/loading/index.jsx';

//lazy-loaded
const LoginEmailActive = lazy(() => import('../pages/activeEmail/index.jsx'));
const UpdatePackageSocial = lazy(() => import('../pages/updatePackageSocail/index.js'));
const StylePersonzalion = lazy(() => import('../pages/veryUser/index.js'));
const RederectPreview = lazy(() => import('../components/common/rederect-preview.js'));
const Page404 = lazy(() => import('../components/common/page404.js'));
const Login = lazy(() => import('../pages/auth/login/index.jsx'));
const Register = lazy(() => import('../pages/auth/register/index.jsx'));
const EmbedBot = lazy(() => import('../pages/embedBot/index.jsx'));
const Dashboard = lazy(() => import('../pages/app/index.jsx'));
const AppShareBot = lazy(() => import('../pages/appShareBot/index.js'));
const AppShareBotGpts = lazy(() => import('../pages/appShareBotGpts/index.js'));
const Document = lazy(() => import('../pages/document/index.js'));
const Home = lazy(() => import('../pages/home/index.js'));
const PrivacyPolicy = lazy(() => import('../pages/home/PrivacyPolicy.js'));
const Paypal = lazy(() => import('../pages/paypal/index.js'));
const PreviewNew = lazy(() => import('../pages/previewGpts/index.js'));
const Preview = lazy(() => import('../pages/preview/index.js'));
const Price = lazy(() => import('../pages/tablePrice/index.js'));
const ServerDown = lazy(() => import('../components/common/server-down.js'));
const AppSetting = lazy(() => import('../pages/appSetting/index.js'));
const AppSettingGPTS = lazy(() => import('../pages/appSettingGPTs/index.js'));
const ResetPassowrd = lazy(() => import('../pages/auth/resetPassword/index.js'));
const StoreBot = lazy(()=> import('../pages/store/index.js'));
const Router = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/embed/:id" element={<EmbedBot />} />
                <Route path="/chat-app/:id" element={<EmbedBot />} />
                <Route path="/embed-assistant/:id" element={<EmbedBot isAssistant />} />
                <Route path="/reset-password/:uid?/:token?" element={<ResetPassowrd />} />
                <Route
                    path="/app/:bot_id/:page/:session_id?/:page_id?/:sender_id?/:type_bot?"
                    element={<AppSetting />}
                />
                <Route
                    path="/app-gpts/:bot_id/:page/:session_id?/:page_id?/:sender_id?/:type_bot?"
                    element={<AppSettingGPTS />}
                />
                <Route path="/store" element={<StoreBot />} />
                <Route path="/app/:page?" element={<Dashboard />} />
                <Route path="/share/:bot_id/:page/:tab?" element={<AppShareBot />} />
                <Route path="/share-gpts/:bot_id/:page/:tab?" element={<AppShareBotGpts />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/very-email/:id?/:social_id?" element={<LoginEmailActive />} />
                <Route path="/buy-package/:bot_id/:converstion_id?/:page_id?" element={<UpdatePackageSocial />} />
                <Route path="/very-personal/:bot_id/:sender_id?" element={<StylePersonzalion />} />
                <Route path="/preview-section/:id/:conversation_id?" element={<Preview />} />
                <Route path="/preview-assistant/:id/:conversation_id?" element={<PreviewNew />} />
                <Route path="/preview/:bot_id/:converstion_id?" element={<RederectPreview />} />
                <Route path="/price/:affiliate_code?" element={<Price />} />
                <Route path="/document" element={<Document />} />
                <Route path="/error" element={<ServerDown />} />
                <Route path="/payment/:code?" element={<Paypal />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
